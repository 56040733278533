import React from "react";
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/Subscription/Subscription')
    )
  } else {
    return (
      import('@components/Subscription/Subscription')
    )
  }
}

const Subscription = ReactDynamicImport({ loader })

const SubscriptionPage = () => <Subscription />

export default SubscriptionPage;
